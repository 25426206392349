import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { PrimeNGModule } from '../../../primeng.module';
import { AddressService } from '../../../services/address/address.service';
import { GoogleAnalyticsService } from '../../../services/google/google-analytics.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { ToastService } from '../../../services/toast/toast.service';
import { WhatsAppService } from '../../../services/whats-app/whats-app.service';
import { Product } from '../../models/product/product';
import Address from '../../models/user/address';
import extractNumbers from '../../utils/extract-numbers';
import { validName, validPhone, validZipCode } from '../../utils/validators';

export class WhatsAppData {
  public address?: Address;
  public cep: string = '';
  public ibgeCode: string = '';
  public name: string = '';
  public phone: string = '';
  public product?: Product | null;
}

export class WhatsAppError {
  public errorCep = false;
  public errorName = false;
  public errorPhone = false;
}

@Component({
  selector: 'app-whats-app',
  standalone: true,
  imports: [NgClass, NgIf, FormsModule, PrimeNGModule],
  templateUrl: './whats-app.component.html',
  styleUrl: './whats-app.component.scss',
  providers: [ToastService, MessageService],
})
export class WhatsAppComponent {
  @Input()
  public productWhatsApp?: Product | null;

  @Output()
  public onClickbtnClose: EventEmitter<boolean> = new EventEmitter();

  public whatsAppData: WhatsAppData = new WhatsAppData();
  public whatsAppError: WhatsAppError = new WhatsAppError();

  constructor(
    private addressService: AddressService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private toastService: ToastService,
    private whatsAppService: WhatsAppService,
    private loaderService: LoaderService
  ) {}

  onClickBtnClose() {
    this.onClickbtnClose.emit(true);
  }

  async onClickSendWhatsApp() {
    this.whatsAppError.errorCep = !validZipCode(this.whatsAppData.cep);
    this.whatsAppError.errorName = !validName(this.whatsAppData.name);
    this.whatsAppError.errorPhone = !validPhone(this.whatsAppData.phone);

    if (
      this.whatsAppError.errorCep ||
      this.whatsAppError.errorName ||
      this.whatsAppError.errorPhone
    ) {
      this.toastService.error('É necessário preencher todos os campos!');
    } else {
      try {
        const address: Address = await this.addressService.getAddress(
          this.whatsAppData.cep
        );
        if (address) {
          this.whatsAppData.address = address;
          this.whatsAppData.product = this.productWhatsApp;
          this.save();
        } else {
          this.toastService.error(
            'CEP inválido! Digite um CEP válido para prosseguir!'
          );
        }
      } catch (error) {
        this.toastService.error(
          'CEP inválido! Digite um CEP válido para prosseguir!'
        );
      }
    }
  }

  async save() {
    try {
      this.loaderService.show();
      this.whatsAppData.cep = extractNumbers(this.whatsAppData?.cep);
      this.whatsAppService.save(this.whatsAppData);
      this.googleAnalyticsService.sendEventWhatsApp(this.whatsAppData);
      this.whatsAppData = new WhatsAppData();
      this.onClickbtnClose.emit(true);
      this.toastService.success(
        'Mensagem enviada com sucesso! Aguarde que entraremos em contato.'
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      this.loaderService.hide();
    }
  }

  openWhatsApp() {
    let text = '';
    if (this.productWhatsApp) {
      text =
        'Olá, gostaria de adquirir um produto:\n*Título:* ' +
        this.productWhatsApp?.name +
        '\n*Link:* https://www.rmpolicarbonatos.com.br/' +
        this.productWhatsApp?.url;
      text = window.encodeURIComponent(text);
    } else {
      text =
        'Olá, gostaria de mais informações!\n*Nome:* ' +
        this.whatsAppData.name +
        '\n*Telefone:* ' +
        this.whatsAppData.phone +
        '\n*CEP:* ' +
        this.whatsAppData.cep;
      text = window.encodeURIComponent(text);
    }

    const urlApi = 'https://api.whatsapp.com/send';
    window?.open(
      urlApi + '?phone=' + environment.whatsAppMain + '&text=' + text
    );
  }
}
