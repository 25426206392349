<div class="has-no-itens-main">
  <p>{{ title }}</p>
  <a [routerLink]="[route]" routerLinkActive="router-link-active">
    <button
      title="{{ labelButton }}"
      class="button-rm"
      [attr.aria-label]="labelButton"
    >
      {{ labelButton }}
    </button>
  </a>
</div>
