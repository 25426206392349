import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeNGModule } from '../../primeng.module';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import Order from '../../shared/models/order/order';

@Component({
  selector: 'app-order-placed',
  standalone: true,
  imports: [BreadcrumbComponent, PrimeNGModule],
  templateUrl: './order-placed.component.html',
  styleUrl: './order-placed.component.scss',
})
export class OrderPlacedComponent implements OnInit {
  public order?: Order;

  constructor(private activeteRoute: ActivatedRoute) {}

  ngOnInit() {
    const orderData = this.activeteRoute.snapshot.queryParamMap.get('order');
    if (orderData) {
      this.order = JSON.parse(orderData);
    }
  }
}
