<div class="cart-main-content-products-mobile">
  <div
    *ngFor="let item of cart.items"
    class="cart-main-content-products-mobile-itens"
  >
    <div class="cart-main-content-products-mobile-item">
      <a href="{{ item?.product?.url }}" target="_blank">
        <img
          [src]="
            item?.product?.mainPhotoS3Url ||
            item?.product?.photos?.slice()?.shift()?.url ||
            ''
          "
          [alt]="item?.product?.name"
          width="80"
          height="80"
        />
        <div class="mobile-item-name">
          <p>
            {{ item?.product?.name }} -
            <span *ngIf="item?.product?.productPerSquareMeter">
              {{
                item?.product?.productPerSquareMeter
                  ? " - (" +
                    item?.customWidth?.toFixed(2) +
                    " m x " +
                    item?.customHeight?.toFixed(2) +
                    " m)"
                  : ""
              }}
            </span>
            {{ item?.product?.brandDescription || "" }} -
            {{ item?.product?.id }}
          </p>
          <small *ngIf="item?.product?.isPersiana()">
            {{ item?.controlSideDescription }}
            - {{ item?.driveDescription }}
            {{
              item?.product?.isPersianaRomana()
                ? ""
                : " - " + item?.panelDescription
            }}
          </small>
        </div>
      </a>
      <div class="mobile-item-value">
        <p *ngIf="(item.product?.discount || 0) <= 0">
          {{ getValueItem(item, false) | customCurrency }}
        </p>

        <p
          *ngIf="(item.product?.discount || 0) > 0"
          [ngClass]="{
            'with-discount': (item.product?.discount || 0) > 0
          }"
        >
          <span>{{ getValueItem(item, false) | customCurrency }}</span
          >{{ getValueItem(item, true) | customCurrency }}
        </p>
        <input
          pInputText
          type="number"
          [(ngModel)]="item.amount"
          (change)="setQuantity(item)"
          name="amount"
          aria-label="Alterar quantidade do produto"
          [min]="0"
          [max]="100"
        />
        <p *ngIf="(item.product?.discount || 0) <= 0">
          {{ getValueItem(item, false) * (item?.amount || 0) | customCurrency }}
        </p>

        <p
          *ngIf="(item.product?.discount || 0) > 0"
          [ngClass]="{
            'with-discount': (item.product?.discount || 0) > 0
          }"
        >
          <span>
            {{
              getValueItem(item, false) * (item?.amount || 0) | customCurrency
            }}</span
          >{{ getValueItem(item, true) * (item?.amount || 0) | customCurrency }}
        </p>

        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-minus"
          class="p-button-danger"
          aria-label="Remover item selecionado do carrinhho!"
          pTooltip="Remover item selecionado do carrinho!"
          tooltipPosition="bottom"
          (click)="removeItem(item)"
          [autoHide]="false"
        ></button>
      </div>
    </div>
  </div>
</div>
