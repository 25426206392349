import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ConfirmationService, MegaMenuItem } from 'primeng/api';
import { PrimeNGModule } from '../../../primeng.module';
import { AuthService } from '../../../services/auth/auth.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import User from '../../models/user/user';
import {
  BreadcrumbComponent,
  BreadcrumbData,
} from '../breadcrumb/breadcrumb.component';
import { AddressCustomersComponent } from './address-customers/address-customers.component';
import { DataCustomersComponent } from './data-customers/data-customers.component';
import { OrdersCustomersComponent } from './orders-customers/orders-customers.component';

@Component({
  selector: 'app-customer-area',
  standalone: true,
  imports: [BreadcrumbComponent, PrimeNGModule, RouterModule],
  templateUrl: './customer-area.component.html',
  styleUrl: './customer-area.component.scss',
})
export class CustomerAreaComponent implements OnInit {
  public activeItem: MegaMenuItem | undefined;
  public category: BreadcrumbData = new BreadcrumbData();
  public pathBreadcrumb: string = '';
  public subCategory: BreadcrumbData = new BreadcrumbData();
  public showCategory = true;
  public showSubCategory = false;
  public itemsMenu: MegaMenuItem[] = [];
  public user: User | undefined = new User();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private metaServices: MetaTagsService
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
      this.setMenu();
    });
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CUSTOMER_AREA,
        url[0]?.path,
        '',
        '',
        null
      );
    });

    this.category = {
      title: 'Área do Cliente',
      path: 'area-do-cliente',
      id: 'area-do-cliente',
    };
  }

  setMenu() {
    if (this.user?.isAdmin) {
      this.setMenuAdmin();
    } else {
      this.setMenuCustomer();
    }
  }

  setMenuAdmin() {
    //TODO:
    this.setMenuCustomer();
  }

  setMenuCustomer() {
    this.itemsMenu = [
      {
        label: 'Meus Pedidos',
        icon: 'pi pi-book',
        routerLink: 'meus-pedidos',
      },
      {
        label: 'Meus Dados',
        icon: 'pi pi-pencil',
        routerLink: 'meus-dados',
      },
      {
        label: 'Meu Endereço',
        icon: 'pi pi-home',
        routerLink: 'meu-endereco',
      },
      {
        label: 'Sair',
        icon: 'pi pi-power-off',
        command: () => this.logout(),
      },
    ];
  }

  async logout() {
    this.confirmationService.confirm({
      message: 'Deseja realmente sair de sua conta?',
      header: 'Confirmação',
      accept: () => {
        this.authService.logout();
      },
    });
  }

  setConfig(component: any) {
    setTimeout(() => {
      this.showCategory = false;
      this.showSubCategory = true;

      if (component instanceof OrdersCustomersComponent) {
        this.subCategory = {
          title: 'Meus Pedidos',
          path: 'meus-pedidos',
          id: 'meus-pedidos',
        };
        this.pathBreadcrumb = 'Área do Cliente - Meus Pedidos';
      } else if (component instanceof DataCustomersComponent) {
        this.subCategory = {
          title: 'Meus Dados',
          path: 'meus-dados',
          id: 'meus-dados',
        };
        this.pathBreadcrumb = 'Área do Cliente - Meus Dados';
      } else if (component instanceof AddressCustomersComponent) {
        this.subCategory = {
          title: 'Meu Endereço',
          path: 'meu-endereco',
          id: 'meu-endereco',
        };
        this.pathBreadcrumb = 'Área do Cliente - Meu Endereço';
      }
    }, 100);
  }
}
