import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token$ = new BehaviorSubject<any>('');

  constructor(private sessionStorageService: SessionStorageService) {}

  async getToken() {
    await this.sessionStorageService
      .get(StorageConstants.RM_ECOMMERCE_TOKEN)
      .then((res: any) => {
        return this.token$.next(res);
      });
  }
}
