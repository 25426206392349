import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { Menubar } from 'primeng/menubar';
import { PrimeNGModule } from '../../../primeng.module';
import { AppInfoService } from '../../../services/app-info/app-info.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { InfoCategory } from '../../models/app/info-category';

@Component({
  selector: 'app-menu-bar',
  standalone: true,
  imports: [PrimeNGModule],
  templateUrl: './menu-bar.component.html',
  styleUrl: './menu-bar.component.scss',
})
export class MenuBarComponent {
  public categories: Array<InfoCategory> = [];
  public items: MenuItem[] = [];

  constructor(
    private appInfoService: AppInfoService,
    private metaTagService: MetaTagsService,
    public router: Router,
    @Inject(DOCUMENT) private dom: any
  ) {}

  ngOnInit() {
    this.appInfoService.getCompleteAppInfo().subscribe((data) => {
      if (data) {
        this.init();
      }
    });
  }

  async init() {
    this.categories =
      await this.appInfoService.getCategoriesWithProductActive();

    const newMenu: MenuItem[] = [];
    this.categories.forEach((category) => {
      const catLink = category?.id?.replaceAll('_', '-')?.toLowerCase();
      const item: MenuItem = {
        id: category.id,
        label: category.description,
        items: category.subCategories?.map((sub) => {
          return {
            id: sub.id,
            label: sub.description,
            routerLink: `${catLink}/${sub?.id
              ?.replaceAll('_', '-')
              ?.toLowerCase()}`,
            //command: (e) => this.takeMenuAction(e),
          };
        }),
      };
      newMenu.push(item);
    });
    newMenu.push(
      {
        label: 'Fale Conosco',
        routerLink: 'fale-conosco',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Catálogos',
        routerLink: 'catalogos',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Políticas, Trocas e Cancelamentos',
        routerLink: 'politicas-trocas-cancelamentos',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Termos e Condições',
        routerLink: 'termos-condicoes-uso',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Aviso de Privacidade',
        routerLink: 'aviso-de-privacidade',
        styleClass: 'mobile-visible',
      },
      {
        label: 'Sobre Nós',
        routerLink: 'quem-somos',
        styleClass: 'mobile-visible',
      }
    );
    this.items = newMenu;

    setTimeout(() => {
      var element = this.dom.getElementsByClassName('p-menubar-button');
      if (element.length > 0) {
        element[0].href = '/';
        element[0].ariaLabel =
          'Navegue pelo nosso menu e encontre nossos produtos!';
      }

      const submenu = this.dom.querySelector('.p-submenu-list');
      if (submenu) {
        submenu.setAttribute('id', 'menu-bar-submenu');
      }

      this.categories.forEach((category, index) => {
        const itemContainer = this.dom.querySelector(`#${category.id}`);
        const item = this.dom.querySelector(`#${category.id} .p-menuitem-text`);
        const itemLink = this.dom.querySelector(`#${category.id} a`);

        if (itemContainer) {
          itemContainer.setAttribute(
            'aria-label',
            `${itemContainer.ariaLabel} - ${index}`
          );
          itemContainer.removeAttribute('aria-haspopup');
          itemContainer.removeAttribute('aria-level');
          itemContainer.removeAttribute('aria-setsize');
          itemContainer.removeAttribute('aria-posinset');
          item.setAttribute('aria-label', category.description);

          itemLink.setAttribute('role', 'button');
          itemLink.setAttribute('aria-label', category?.description);
          itemLink.setAttribute('href', '#');

          const itemSubmenu = this.dom.querySelector(
            `#${category.id} .p-submenu-list`
          );

          itemSubmenu.setAttribute('id', `submenu-${index}`);
        }
      });
    }, 500);
  }

  takeMenuAction(event: MenuItemCommandEvent) {
    if (event) {
      const url = event?.item?.url || '';
      this.router.navigateByUrl(url);
    }
  }

  onClickMenu(e: MouseEvent) {
    e.preventDefault();
    const isMenuMobileOpen = this.dom.getElementsByClassName(
      'p-menubar-mobile-active'
    );
    this.addOverflowHiddenToBody(isMenuMobileOpen?.length > 0);
  }

  addOverflowHiddenToBody(add: boolean, menubar?: Menubar) {
    menubar?.hide();
    this.metaTagService.classToBody('overflow-hidden', add);
  }
}
