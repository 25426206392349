import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';
import Carrying from '../../models/freight/carrying';
import QuoteList from '../../models/freight/quote-list';
import Address from '../../models/user/address';
import { SharedModule } from '../../shared.module';
import { ZipCodeInputComponent } from '../zip-code-input/zip-code-input.component';

@Component({
  selector: 'app-freight-calculate',
  standalone: true,
  imports: [NgIf, PrimeNGModule, ZipCodeInputComponent, SharedModule],
  templateUrl: './freight-calculate.component.html',
  styleUrl: './freight-calculate.component.scss',
})
export class FreightCalculateComponent {
  @Input()
  public quantitySelected = 1;

  @Input()
  public loadingFreight = false;

  @Input()
  public quotes: QuoteList | undefined;

  @Output()
  public onSetQuantitySelected: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onChangeZipCode: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public onZipCodeError: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public onFoundAddress: EventEmitter<Address> = new EventEmitter<Address>();

  public address?: Address;

  setQuantitySelected(event: any) {
    this.onSetQuantitySelected.emit(event);
  }

  changeZipCode() {
    this.onChangeZipCode.emit(true);
  }

  zipCodeError() {
    this.onZipCodeError.emit(true);
  }

  addressFound(address: Address) {
    this.address = address;
    this.onFoundAddress.emit(address);
  }

  getCarrier(index: number): Carrying {
    const carrier = this.quotes?.carriers
      ? this.quotes?.carriers[index]
      : new Carrying();
    return carrier;
  }
}
