<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-breadcrumb
  [category]="category"
  [subCategory]="subCategory"
  [showProduct]="!querySearch"
  [productName]="product?.name || ''"
  [justShowProduct]="querySearch"
  [querySearch]="querySearch"
></app-breadcrumb>

<div class="subcategories-main">
  <app-products-shop
    *ngIf="querySearch"
    [configCategories]="configCategories"
  ></app-products-shop>
  <app-products-shop-details
    *ngIf="(product?.id || 0) > 0"
    [product]="product"
  ></app-products-shop-details>
</div>
