import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';
import { PagSeguroService } from '../../../services/pag-seguro/pag-seguro.service';
import { ToastService } from '../../../services/toast/toast.service';
import Card from '../../models/payment/card';
import { ValueUtils } from '../../utils/value';

export class CardInputError {
  public errorCardNumber = false;
  public errorCvv = false;
  public errorExpirationMonth = false;
  public errorExpirationYear = false;
  public errorName = false;
  public errorInstallments = false;
}

@Component({
  selector: 'app-credit-card-input',
  standalone: true,
  imports: [NgIf, NgClass, PrimeNGModule],
  templateUrl: './credit-card-input.component.html',
  styleUrl: './credit-card-input.component.scss',
})
export class CreditCardInputComponent {
  public static CREDIT_CARD = 'CREDIT_CARD';
  public static MEDIUM = 'MEDIUM';
  public static BRAND = 'brand';
  public static INSTALLMENT = 'installments';

  @Input()
  public card?: Card;

  @Input()
  public paymentMethods: any;

  @Input()
  public cardInputError?: CardInputError;

  @Input()
  public disabled = false;

  public brand: any;
  public installments: any[] = [];
  public optionsMonth = [
    { description: 'Janeiro', value: '01' },
    { description: 'Fevereiro', value: '02' },
    { description: 'Março', value: '03' },
    { description: 'Abril', value: '04' },
    { description: 'Maio', value: '05' },
    { description: 'Junho', value: '06' },
    { description: 'Julho', value: '07' },
    { description: 'Agosto', value: '08' },
    { description: 'Setembro', value: '09' },
    { description: 'Outubro', value: '10' },
    { description: 'Novembro', value: '11' },
    { description: 'Dezembro', value: '12' },
  ];
  public optionsYears = [
    { description: '2024', value: '2024' },
    { description: '2025', value: '2025' },
    { description: '2026', value: '2026' },
    { description: '2027', value: '2027' },
    { description: '2028', value: '2028' },
    { description: '2029', value: '2029' },
    { description: '2030', value: '2030' },
    { description: '2031', value: '2031' },
    { description: '2032', value: '2032' },
    { description: '2033', value: '2033' },
    { description: '2034', value: '2034' },
    { description: '2035', value: '2035' },
  ];

  constructor(
    private pagSeguroService: PagSeguroService,
    private toastService: ToastService
  ) {}

  onComplete() {
    this.validCardNumber();
  }

  onKeydown() {
    this.validCardNumber();
  }

  validCardNumber() {
    if (this.card?.cardNumber) {
      const value: string = this.card?.cardNumber?.replace(/\D/g, '') || '';
      if (value.length === 16) {
        this.getBrand(value);
      } else {
        this.brand = undefined;
        this.card.brand = undefined;
        this.installments = [];
      }
    }
  }

  getImageBrand() {
    if (this.paymentMethods) {
      const ls =
        this.paymentMethods[CreditCardInputComponent.CREDIT_CARD].options;
      const brand = ls[this.brand?.name.toUpperCase()];
      if (brand) {
        const link = brand.images[CreditCardInputComponent.MEDIUM].path;
        return `https://stc.pagseguro.uol.com.br${link}`;
      }
    }
    return '';
  }

  changeInstallments(quantity: any) {
    if (this.card) {
      this.card.installments = this.installments.find(
        (inst: any) => inst?.quantity?.toString() === quantity
      );
    }
  }

  async getBrand(numberCard: string) {
    if (this.card && this.cardInputError) {
      this.brand = null;
      this.card.brand = undefined;
      try {
        const res: any = await this.pagSeguroService.getBrand(numberCard);
        this.cardInputError.errorCardNumber = false;
        this.brand = res[CreditCardInputComponent.BRAND];
        this.card.brand = this.brand?.name;
        this.getInstallments(this.card.totalValue, this.brand.name);
      } catch (err) {
        this.cardInputError.errorCardNumber = true;
        this.toastService.error('Número do cartão inválido!');
      }
    }
  }

  async getInstallments(value: number | undefined, brand: string) {
    this.installments = [];
    try {
      const res: any = await this.pagSeguroService.getInstallments(
        value,
        brand
      );
      if (res) {
        this.installments = res[CreditCardInputComponent.INSTALLMENT][brand];
        let msg = '';
        this.installments.forEach((el: any) => {
          if (el.quantity === 1) {
            msg = `À vista ${ValueUtils.currency(el.installmentAmount)}`;
          } else {
            if (el.quantity <= 4) {
              msg = `${el.quantity}x sem juros ${ValueUtils.currency(
                el.installmentAmount
              )}`;
            } else {
              msg = `${el.quantity}x com juros ${ValueUtils.currency(
                el.installmentAmount
              )}`;
            }
          }
          el.description = msg;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  updateCardField<K extends keyof Card>(field: K, value: Card[K]) {
    if (this.card) {
      this.card[field] = value;
    }
  }
}
