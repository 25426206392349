import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeNGModule } from '../../primeng.module';
import { ContactService } from '../../services/contact/contact.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { ContactFormComponent } from '../../shared/components/contact-form/contact-form.component';

@Component({
  selector: 'app-dealer',
  standalone: true,
  imports: [BreadcrumbComponent, ContactFormComponent, PrimeNGModule],
  templateUrl: './dealer.component.html',
  styleUrl: './dealer.component.scss',
})
export class DealerComponent {
  public typeDealer = ContactService.TYPE_DEALER;

  constructor(
    private activatedRoute: ActivatedRoute,
    private metaServices: MetaTagsService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_DEALER,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }
}
