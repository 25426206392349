import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PrimeNGModule } from '../../primeng.module';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { ToastService } from '../../services/toast/toast.service';
import { UserService } from '../../services/user/user.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { validPassword } from '../../shared/utils/validators';
import { TWO_SECONDS_IN_MS, wait } from '../../shared/utils/wait';
import { UserLoginError } from '../login-customer/login-customer.component';

export class UserForgot {
  public email: string = '';
  public emailURL: string = '';
  public password: string = '';
  public passwordConfirm: string = '';
  public token: string = '';
}

@Component({
  selector: 'app-forgot',
  standalone: true,
  imports: [PrimeNGModule, BreadcrumbComponent, RecaptchaModule],
  templateUrl: './forgot.component.html',
  styleUrl: './forgot.component.scss',
  providers: [ToastService, MessageService],
})
export class ForgotComponent {
  public userError: UserLoginError = new UserLoginError();
  public userForgot: UserForgot = new UserForgot();
  public captcha = false;
  public siteKey = environment.siteKeyGoogle;

  constructor(
    private activatedRoute: ActivatedRoute,
    private metaServices: MetaTagsService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_FORGOT,
        url[0]?.path,
        '',
        '',
        null
      );
    });

    this.userForgot.email =
      this.activatedRoute.snapshot.paramMap.get('email') || '';
    this.userForgot.token =
      this.activatedRoute.snapshot.paramMap.get('token') || '';

    if (!this.userForgot.email || !this.userForgot.token) {
      this.toastService.error(
        'Token inválido! Solicite novamente uma nova senha!'
      );
      await wait(TWO_SECONDS_IN_MS);
      this.userService.goToLogin();
    }
  }

  async onClickChange() {
    if (!this.validationUser()) {
      this.toastService.error('Informe sua senha e confirmação!');
      return;
    }

    if (!this.captcha) {
      this.toastService.error(
        'Faça a validação de anti-spam e tente novamente!'
      );
      return;
    }

    const msg = validPassword(
      this.userForgot.password,
      this.userForgot.passwordConfirm
    );
    if (msg) {
      this.toastService.error(msg);
      return;
    }

    try {
      await this.userService.changePassword(this.userForgot);
      this.toastService.success('Sua senha foi alterada com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      this.userService.goToLogin();
    } catch (error: any) {
      this.toastService.error(error);
      await wait(TWO_SECONDS_IN_MS);
      this.userService.goToLogin();
    }
  }

  resolvedcCaptcha(captchaResponse: string | null) {
    this.captcha = captchaResponse ? true : false;
  }

  validationUser() {
    this.userError.errorPassword = !this.userForgot.password;
    this.userError.errorPasswordConfirm = !this.userForgot.passwordConfirm;
    return (
      !this.userError.errorPassword && !this.userError.errorPasswordConfirm
    );
  }
}
