import { NgIf } from '@angular/common';

import { afterNextRender, Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrimeNGModule } from '../../../primeng.module';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { WhatsAppService } from '../../../services/whats-app/whats-app.service';
import { Product } from '../../models/product/product';
import { SharedModule } from '../../shared.module';
import { WhatsAppComponent } from '../whats-app/whats-app.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NgIf, WhatsAppComponent, PrimeNGModule, SharedModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnDestroy {
  @Input() justRights: boolean = false;

  public static readonly COOKIE_PRIVACY = 'cookie_accept_policy';
  public static readonly COOKIE_PRIVACY_FULL =
    'cookie_accept_policy=true; expires=Thu, 18 Dec 2099 12:00:00 UTC; path=/';

  public showWhatsApp = false;
  public showCookies = false;

  public subscribeData = {
    email: '',
    name: '',
    phone: '',
  };

  public productWhatsApp?: Product | null;
  private subscriptionWhatsProduct?: Subscription;
  private subscriptionWhats?: Subscription;

  constructor(
    private localStorageService: LocalStorageService,
    private whatsAppService: WhatsAppService
  ) {
    afterNextRender(() => {
      this.init();
    });
  }

  async init() {
    this.subscriptionWhatsProduct = this.whatsAppService
      .getOpenWhatsAppProduct()
      .subscribe(async (product: any) => {
        if (product) {
          this.productWhatsApp = product;
          this.showWhatsApp = true;
        } else {
          this.productWhatsApp = null;
        }
      });

    this.subscriptionWhats = this.whatsAppService
      .getOpenWhatsApp()
      .subscribe(async (show: boolean) => {
        this.showWhatsApp = show;
      });

    const cookie = await this.localStorageService.get(
      FooterComponent.COOKIE_PRIVACY
    );
    this.showCookies = !cookie;
  }

  onClickCookies() {
    this.localStorageService.set(FooterComponent.COOKIE_PRIVACY, true);
    this.showCookies = false;
  }

  onClickbtnCloseWhats() {
    this.whatsAppService.setOpenWhatsAppProduct(null);
    this.showWhatsApp = false;
  }

  onClickbtnWhats() {
    this.whatsAppService.setOpenWhatsAppProduct(null);
    this.showWhatsApp = !this.showWhatsApp;
  }

  ngOnDestroy() {
    this.subscriptionWhatsProduct?.unsubscribe();
    this.subscriptionWhats?.unsubscribe();
  }
}
