import { Injectable } from '@angular/core';
import { ProductsService } from '../../../services/product/products.service';
import { ValueUtils } from '../../utils/value';
import { InfoCategory } from '../app/info-category';
import { BlindsOptionsEnum } from '../app/info-enum';
import { Product } from '../product/product';
import CartProductCheckout from './cart-product-checkout';

@Injectable({
  providedIn: 'root',
})
export default class CartCheckout {
  public id?: number;
  public cartProducts?: Array<CartProductCheckout>;

  constructor(private productsService: ProductsService) {}

  fromJson(cart: any, categories: InfoCategory[]) {
    if (cart) {
      this.id = cart?.id;
      if (cart.cartProducts) {
        this.cartProducts = [];
        cart.cartProducts.forEach((cp: any) => {
          cp = Object.assign(new CartProductCheckout(), cp);
          const product: Product = new Product();
          product.fromJson(cp.product, categories);
          cp.product = product;
          cp.controlSideDescription =
            cp?.controlSide === BlindsOptionsEnum.CONTROL_SIDE_LEFT
              ? 'Esquerdo'
              : cp?.controlSide === BlindsOptionsEnum.CONTROL_SIDE_RIGHT
              ? 'Direito'
              : '';
          cp.driveDescription =
            cp?.drive === BlindsOptionsEnum.DRIVE_MOTOR
              ? 'Motorizado - ' + ValueUtils.currency(this.valueMotor)
              : cp?.drive === BlindsOptionsEnum.DRIVE_MANUAL
              ? 'Manual'
              : '';
          cp.panelDescription =
            cp?.panel === BlindsOptionsEnum.WITH_PANEL
              ? 'Com Bandô - ' +
                ValueUtils.currency(
                  this.getValueBando(
                    cp?.customWidth,
                    product?.subCategory || ''
                  )
                )
              : cp?.panel === BlindsOptionsEnum.WITHOUT_PANEL
              ? 'Sem Bandô'
              : '';
          cp.typeFixingDescription =
            cp?.typeFixing === BlindsOptionsEnum.TYPE_FIXING_WALL
              ? 'Na parede'
              : cp?.typeFixing === BlindsOptionsEnum.TYPE_FIXING_CEILING
              ? 'No teto'
              : '';
          this.cartProducts?.push(cp);
        });
      }
    }
  }

  public get valueMotor(): number {
    return this.productsService.getValueMotor();
  }

  public getValueBando(width: number, model: string): number {
    return this.productsService.getValueTotalBando(width, model);
  }
}
