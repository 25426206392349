import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PrimeNGModule } from '../../../primeng.module';
import { CartService } from '../../../services/cart/cart.service';
import { Cart, CartItem } from '../../models/cart/cart';
import FreightSelected from '../../models/freight/freight-selected';
import QuoteList from '../../models/freight/quote-list';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-cart-products-mobile',
  standalone: true,
  imports: [NgFor, NgIf, NgClass, FormsModule, SharedModule, PrimeNGModule],
  templateUrl: './cart-products-mobile.component.html',
  styleUrl: './cart-products-mobile.component.scss',
})
export class CartProductsMobileComponent {
  @Input()
  public cart: Cart = {};

  @Input()
  public freightSelected: FreightSelected | undefined;

  @Input()
  public loadingFreight = false;

  @Input()
  public quotes: QuoteList | undefined;

  @Input()
  public freightError: string | undefined;

  @Output()
  public onSetQuantity: EventEmitter<CartItem> = new EventEmitter();

  @Output()
  public onRemoveItem: EventEmitter<CartItem> = new EventEmitter();

  constructor(private cartService: CartService) {}

  getValueItem(cartProduct: CartItem, whithDiscount: boolean) {
    return this.cartService.getValueItem(cartProduct, whithDiscount);
  }

  setQuantity(item: CartItem) {
    this.onSetQuantity.emit(item);
  }

  removeItem(item: CartItem) {
    this.onRemoveItem.emit(item);
  }
}
