import Coupon from '../coupons/coupon';
import Freight from '../freight/freight';
import FreightSelected from '../freight/freight-selected';
import Address from '../user/address';
import CheckoutPayment from './checkout-payment';
import ShippingAddress from './shipping-address';

export default class Checkout {
  public cartId?: number;
  public freightSelected?: FreightSelected;
  public payment?: CheckoutPayment;
  public shippingAddress?: ShippingAddress;
  public coupon?: Coupon;

  constructor(
    cartId: number | undefined,
    freightSelected: FreightSelected | undefined,
    payment: CheckoutPayment,
    address: Address,
    sameAddressForInvoice: boolean
  ) {
    this.cartId = cartId;
    this.freightSelected = freightSelected;
    if (this.freightSelected?.optionFreight === Freight.STORE_PICKUP) {
      this.freightSelected.freight = null;
    }
    this.payment = payment;
    this.shippingAddress = new ShippingAddress();
    this.shippingAddress.fromAddress(address, sameAddressForInvoice);
  }
}
