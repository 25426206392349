import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PrimeNGModule } from '../../../primeng.module';
import { AddressService } from '../../../services/address/address.service';
import { ToastService } from '../../../services/toast/toast.service';
import Address from '../../models/user/address';
import extractNumbers from '../../utils/extract-numbers';

export class AddressError {
  public errorAddress = false;
  public errorCity = false;
  public errorDistrict = false;
  public errorNumber = false;
  public errorUF = false;
  public errorZipCode = false;
  public errorComplement = false;

  validAll(valid: boolean) {
    this.errorAddress = valid;
    this.errorCity = valid;
    this.errorDistrict = valid;
    this.errorComplement = valid;
    this.errorNumber = valid;
    this.errorUF = valid;
    this.errorZipCode = valid;
  }

  validByAddress(address: Address) {
    this.errorAddress = !address.address;
    this.errorCity = !address.city;
    this.errorDistrict = !address.district;
    this.errorComplement = !address.complement;
    this.errorNumber = !address.numberAddress;
    this.errorUF = !address.uf;
    this.errorZipCode = !address.zipCode;
  }
}

export class AddressDisable {
  public address = true;
  public all = true;
  public complement = true;
  public district = true;
  public number = true;
}

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [NgIf, NgClass, FormsModule, PrimeNGModule],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
})
export class AddressComponent {
  @Input()
  public address?: Address | undefined;

  @Input()
  public addressError?: AddressError;

  @Input()
  public addressDisable?: AddressDisable;

  @Input()
  public showKnowZipCoded: boolean = true;

  @Input()
  public showLabel: boolean = true;

  @Output()
  public clearedZip: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public foundAddress: EventEmitter<Address> = new EventEmitter<Address>();

  constructor(
    private addressService: AddressService,
    private toastService: ToastService
  ) {}

  async getAddress() {
    const valueNumber = extractNumbers(this.address?.zipCode || '');
    if (valueNumber?.length === 8) {
      try {
        const address: Address = await this.addressService.getAddress(
          valueNumber
        );
        if (address) {
          this.address!.address = address.address;
          this.address!.city = address.city;
          this.address!.district = address.district;
          this.address!.uf = address.uf;

          this.addressDisable!.address = !!address.address;
          this.addressDisable!.district = !!address.district;
          this.addressDisable!.complement = false;
          this.addressDisable!.number = false;

          this.addressError?.validByAddress(this.address!);

          this.foundAddress.emit(address);
        }
      } catch (error) {
        this.toastService.error('CEP não localizado!');
      }
    } else {
      this.clearedZip.emit(true);
      this.disableAllFields(true);
      this.cleanAddress();
    }
  }

  onCleanAddress() {
    const valueNumber = extractNumbers(this.address?.zipCode || '');
    if (valueNumber?.length < 8) {
      this.clearedZip.emit(true);
      this.disableAllFields(true);
      this.cleanAddress();
    }
  }

  disableAllFields(enabled: boolean) {
    if (this.address) {
      this.addressDisable!.address = enabled;
      this.addressDisable!.all = enabled;
      this.addressDisable!.complement = enabled;
      this.addressDisable!.district = enabled;
      this.addressDisable!.number = enabled;
    }
  }

  cleanAddress() {
    if (this.address) {
      this.address.address = '';
      this.address.city = '';
      this.address.complement = '';
      this.address.district = '';
      this.address.numberAddress = '';
      this.address.uf = '';
    }
  }

  updateAddressField<K extends keyof Address>(field: K, value: Address[K]) {
    if (this.address) {
      this.address[field] = value;
    }
  }
}
