import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-header-cart-checkout',
  standalone: true,
  imports: [NgClass, RouterModule],
  templateUrl: './header-cart-checkout.component.html',
  styleUrl: './header-cart-checkout.component.scss',
})
export class HeaderCartCheckoutComponent {
  public step = 1;

  constructor(private router: Router) {
    this.updateStep();
    this.router.events.subscribe(() => this.updateStep());
  }

  updateStep() {
    const url = this.router.url;
    if (url.includes('carrinho')) this.step = 1;
    else if (url.includes('entrega')) this.step = 2;
    else if (url.includes('pagamento')) this.step = 3;
    else if (url.includes('confirmacao')) this.step = 4;
  }
}
