<app-breadcrumb [path]="'Pedido Realizado'" [showTitle]="true"></app-breadcrumb>
<div class="order-placed-main">
  <div class="container">
    <div class="order-placed-main-content">
      <h2>Seus pedido foi realizado com sucesso!</h2>
      <i class="pi pi-check-circle"></i>
      <h3>Número:{{ order?.id }}</h3>
      <h2>AGUARDANDO PAGAMENTO</h2>
      <h3>Você receberá atualizações no e-mail:</h3>
      <h3>
        {{ order?.emailUser }}
      </h3>
      <a routerLink="/area-do-cliente/meus-pedidos">
        <button
          title="Acessar seus pedidos"
          aria-label="Acessar seus pedidos"
          class="button-rm"
        >
          ACOMPANHAR PEDIDO
        </button>
      </a>
    </div>
  </div>
</div>
