<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<div class="address-customers-main">
  <h2>MEU ENDEREÇO</h2>
  <app-address
    [address]="address"
    [addressError]="addressError"
    [addressDisable]="addressDisable"
  ></app-address>
  <button
    class="button-rm"
    aria-label="Atualizar seu endereço"
    (click)="onClickUpdate()"
  >
    ALTERAR
  </button>
</div>
