import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CartService } from '../../../services/cart/cart.service';
import { FreightService } from '../../../services/freight/freight.service';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { SessionStorageService } from '../../../services/session-storage/session-storage.service';
import { UserService } from '../../../services/user/user.service';
import { CartItem } from '../../../shared/models/cart/cart';
import CalculateShipping from '../../../shared/models/freight/calculate-shipping';
import Carrying from '../../../shared/models/freight/carrying';
import Freight from '../../../shared/models/freight/freight';
import FreightSelected from '../../../shared/models/freight/freight-selected';
import ItemShipping from '../../../shared/models/freight/item-shipping';
import { StorageConstants } from '../../../shared/models/storage/storage-constants';

@Injectable({
  providedIn: 'root',
})
class FreightGuardService {
  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private cartService: CartService,
    private freightService: FreightService
  ) {}

  async canActivate(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const zipCode = await this.localStorageService.get(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE
      );
      const freightStorage = await this.sessionStorageService.get(
        StorageConstants.RM_ECOMMERCE_FREIGHT
      );

      if (zipCode && freightStorage) {
        resolve(true);
      } else if (zipCode && !freightStorage) {
        const cartItems = await this.cartService.getItems(null);
        const calculate: CalculateShipping = new CalculateShipping();
        calculate.cep = zipCode;
        calculate.itens = cartItems?.map((i: CartItem) => {
          return new ItemShipping(i);
        });

        const quotes = await this.freightService.calculateShippingOrCart(
          calculate
        );
        const store =
          quotes?.carriers
            ?.filter(
              (item: Carrying) => item?.optionFreight === Freight.CARRYING
            )
            .shift() || new Carrying();
        const freightSelected = new FreightSelected(
          store,
          quotes?.tokenOffer || ''
        );
        await this.sessionStorageService.set(
          StorageConstants.RM_ECOMMERCE_FREIGHT,
          freightSelected
        );
        resolve(true);
      } else {
        this.userService.goToCart();
      }
    });
  }
}

export const freightGuard: CanActivateFn = (route, state) => {
  return inject(FreightGuardService).canActivate();
};
