<p-progressSpinner
  *ngIf="loadingFreight"
  strokeWidth="3"
  aria-label="Buscando informações de frete"
></p-progressSpinner>

<div class="freight-quotes-desktop" *ngIf="_quotes && _quotes.carriers">
  <div
    class="freight-quotes-desktop-carrying"
    *ngIf="(getCarrying()?.priceFreight || 0) > 0"
  >
    <p>
      {{ getCarrying()?.displayName }}
    </p>
    <p>
      {{ getCarrying()?.deliveryTime }}
      {{ getCarrying()?.deliveryTime === 1 ? "dia" + " útil" : "dias úteis" }}
    </p>
    <p *ngIf="getCarrying()?.priceFreight || 0 > 0">
      {{ getCarrying()?.priceFreight | customCurrency }}
    </p>
  </div>
  <p
    class="freight-quotes-desktop-carrying-free"
    *ngIf="(getCarrying()?.priceFreight || 0) <= 0"
  >
    FRETE GRÁTIS PARA SEU CEP
  </p>
  <div class="freight-quotes-desktop-store">
    <p-checkbox
      inputId="storePick"
      name="storePick"
      [(ngModel)]="storePick"
      value="{{ storePick }}"
      (onChange)="onChangeStorePick($event)"
    />
    <label for="storePick"
      >Ou retire na loja em {{ getStorePick()?.deliveryTime }}
      {{ getStorePick()?.deliveryTime === 1 ? "dia" + " útil" : "dias úteis" }}
    </label>
  </div>
  <div class="freight-quotes-desktop-error" *ngIf="!_quotes && errorFreight">
    <p>{{ errorFreight }}</p>
  </div>
</div>
