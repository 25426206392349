export default class Phone {
  public areaCode?: string;
  public number?: string;

  constructor(phone: string) {
    if (phone) {
      phone = phone?.replace(/\D/g, '');
      this.areaCode = phone.substring(0, 2);
      this.number = phone.substring(2);
    }
  }
}
