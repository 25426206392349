import InfoCoupon from '../coupons/info-coupon';
import { AbstractInfoEnum } from './abstract-info-enum';
import { InfoCategory } from './info-category';

export enum CategoriesEnum {
  ACESSORIOS_DE_INSTALACAO = 'ACESSORIOS_DE_INSTALACAO',
  COBERTURAS_EM_POLICARBONATO = 'COBERTURAS_EM_POLICARBONATO',
  COMUNICACAO_VISUAL = 'COMUNICACAO_VISUAL',
  DECORACAO = 'DECORACAO',
  KITS = 'KITS',
  PERFIS_EM_ALUMINIO = 'PERFIS_EM_ALUMINIO',
  PERSIANAS = 'PERSIANAS',
  PRODUCTS_ON_OFFER = 'products_on_offer',
  PRODUCTS_ON_OFFER_PT = 'PRODUTOS_EM_OFERTA',
  TOLDOS = 'TOLDOS',
}

export enum SubCategoriesEnum {
  //Kits
  POLICARBONATO_ALVEOLAR_KIT = 'POLICARBONATO_ALVEOLAR_KIT',
  POLICARBONATO_COMPACTO_KIT = 'POLICARBONATO_COMPACTO_KIT',

  //Toldos
  TOLDOS_ARTICULADO = 'TOLDOS_ARTICULADO',
  TOLDOS_CORTINA = 'TOLDOS_CORTINA',
  _ACESSORIOS_TOLDOS = '_ACESSORIOS_TOLDOS',
  _LONA_PVC = '_LONA_PVC',
  MOTORES_PARA_TOLDOS = 'MOTORES_PARA_TOLDOS',

  //CoberturasEmPolicarbonato
  POLICARBONATO_ALVEOLAR = 'POLICARBONATO_ALVEOLAR',
  POLICARBONATO_COMPACTO = 'POLICARBONATO_COMPACTO',
  ESCUDOS_PROTECAO = 'ESCUDOS_PROTECAO',

  //ComunicacaoVisual
  ACM = 'ACM',
  ACRILICO = 'ACRILICO',
  PVC_EXPANDIDO = 'PVC_EXPANDIDO',
  CHAPAS_PS = 'CHAPAS_PS',

  //PerfisEmAluminio
  CANTONEIRAS_BARRA_CHATA = 'CANTONEIRAS_BARRA_CHATA',
  PERFIS_FIXACAO_ACABAMENTO = 'PERFIS_FIXACAO_ACABAMENTO',
  PERFIS_ESTRUTURAIS = 'PERFIS_ESTRUTURAIS',

  //AcessoriosDeInstalacao
  DISCOS_CORTE_DESBASTE = 'DISCOS_CORTE_DESBASTE',
  FITAS_SELANTES = 'FITAS_SELANTES',
  BUCHAS_PARAFUSOS = 'BUCHAS_PARAFUSOS',
  PLACAS_SINALIZACAO = 'PLACAS_SINALIZACAO',
  PLASTICOS_EMBALAGENS = 'PLASTICOS_EMBALAGENS',
  ITENS_DE_SEGURANCA = 'ITENS_DE_SEGURANCA',

  //Persianas
  ACESSORIOS_PERSIANAS = 'ACESSORIOS_PERSIANAS',
  DOUBLE_VISION = 'DOUBLE_VISION',
  HORIZONTAL = 'HORIZONTAL',
  PAINEL = 'PAINEL',
  PRONTA_ENTREGA = 'PRONTA_ENTREGA',
  ROLO = 'ROLO',
  ROMANA = 'ROMANA',
  ROMANA_DE_TETO = 'ROMANA_DE_TETO',

  //Decoracao
  PORTA_RETRATO = 'PORTA_RETRATO',
}

export enum AttributeEnum {
  ACTUATION_SIDES = 'actuationSides',
  ACTUATION_TYPES = 'actuationTypes',
  ALUMINUM_WALL_THICKNESS = 'aluminumWallThickness',
  BRANDS = 'brands',
  CAPABILITIES = 'capabilities',
  COLORS = 'colors',
  COLORS_CODE = 'colorsCode',
  FABRIC_COMPOSITIONS = 'fabricCompositions',
  FINISHES = 'finishes',
  HEIGHT_THICKNESS = 'heightThickness',
  LENGTHS = 'lengths',
  MODELS = 'models',
  POTENCIES = 'potencies',
  SPEEDS = 'speeds',
  UNITS_COMMERCIALIZE = 'unitsCommercialize',
  VOLTAGES = 'voltages',
  WIDTHS = 'widths',
}

export enum FilterSubCategoriesKitsEnum {
  PERFIS_ESTRUTURAIS = SubCategoriesEnum.PERFIS_ESTRUTURAIS,
  FITAS_SELANTES = SubCategoriesEnum.FITAS_SELANTES,
  BUCHAS_PARAFUSOS = SubCategoriesEnum.BUCHAS_PARAFUSOS,
  PERFIS_FIXACAO_ACABAMENTO = SubCategoriesEnum.PERFIS_FIXACAO_ACABAMENTO,
}

export enum FilterSubCategoriesChapasEnum {
  FITAS_SELANTES = SubCategoriesEnum.FITAS_SELANTES,
  BUCHAS_PARAFUSOS = SubCategoriesEnum.BUCHAS_PARAFUSOS,
  PERFIS_FIXACAO_ACABAMENTO = SubCategoriesEnum.PERFIS_FIXACAO_ACABAMENTO,
}

export enum FilterSubCategoriesToldosEnum {
  TOLDOS_ARTICULADO = SubCategoriesEnum.TOLDOS_ARTICULADO,
  TOLDOS_CORTINA = SubCategoriesEnum.TOLDOS_CORTINA,
  MOTORES_PARA_TOLDOS = SubCategoriesEnum.MOTORES_PARA_TOLDOS,
  _ACESSORIOS_TOLDOS = SubCategoriesEnum._ACESSORIOS_TOLDOS,
  _LONA_PVC = SubCategoriesEnum._ACESSORIOS_TOLDOS,
}

export enum FilterSubCategoriesComunicacaoEnum {
  ACM = SubCategoriesEnum.ACM,
  ACRILICO = SubCategoriesEnum.ACRILICO,
  PVC_EXPANDIDO = SubCategoriesEnum.PVC_EXPANDIDO,
  FITAS_SELANTES = SubCategoriesEnum.FITAS_SELANTES,
  BUCHAS_PARAFUSOS = SubCategoriesEnum.BUCHAS_PARAFUSOS,
  DISCOS_CORTE_DESBASTE = SubCategoriesEnum.DISCOS_CORTE_DESBASTE,
}

export enum FilterSubCategoriesPerfisEnum {
  DISCOS_CORTE_DESBASTE = SubCategoriesEnum.DISCOS_CORTE_DESBASTE,
  FITAS_SELANTES = SubCategoriesEnum.FITAS_SELANTES,
  BUCHAS_PARAFUSOS = SubCategoriesEnum.BUCHAS_PARAFUSOS,
}

export enum FilterSubCategoriesAcessoriosEnum {
  TOLDOS_ARTICULADO = SubCategoriesEnum.TOLDOS_ARTICULADO,
  TOLDOS_CORTINA = SubCategoriesEnum.TOLDOS_CORTINA,
}

export enum FilterSubCategoriesPersianasEnum {
  DOUBLE_VISION = SubCategoriesEnum.DOUBLE_VISION,
  HORIZONTAL = SubCategoriesEnum.HORIZONTAL,
  PAINEL = SubCategoriesEnum.PAINEL,
  PRONTA_ENTREGA = SubCategoriesEnum.PRONTA_ENTREGA,
  ROLO = SubCategoriesEnum.ROLO,
  ROMANA = SubCategoriesEnum.ROMANA,
  ROMANA_DE_TETO = SubCategoriesEnum.ROMANA_DE_TETO,
}

export enum FilterSubCategoriesDecoracaoEnum {
  PORTA_RETRATO = SubCategoriesEnum.PORTA_RETRATO,
}

export enum GroupingAttributesEnum {
  GROUP_BY_DIMENSIONS = 'GROUP_BY_DIMENSIONS',
  GROUP_BY_COLOR = 'GROUP_BY_COLOR',
}

export enum BlindsOptionsEnum {
  CONTROL_SIDE_LEFT = 'SIDE_LEFT',
  CONTROL_SIDE_RIGHT = 'SIDE_RIGHT',
  DRIVE_MANUAL = 'DRIVE_MANUAL',
  DRIVE_MOTOR = 'DRIVE_MOTOR',
  TYPE_FIXING_CEILING = 'FIXING_CEILING',
  TYPE_FIXING_WALL = 'FIXING_WALL',
  WITH_PANEL = 'WITH_PANEL',
  WITHOUT_PANEL = 'WITHOUT_PANEL',
}

export class InfoEnum {
  categories?: Array<InfoCategory>;
  orderStatus?: Array<AbstractInfoEnum>;
  productOrigins?: Array<AbstractInfoEnum>;
  marketplaces?: Array<AbstractInfoEnum>;
  groupingAttributes?: Array<AbstractInfoEnum>;
  coupon?: InfoCoupon;

  fromJson(enumeretionsObj: any) {
    if (
      enumeretionsObj?.categories != null &&
      enumeretionsObj?.categories.length > 0
    ) {
      this.categories = [];
      let newCategory: InfoCategory;
      enumeretionsObj?.categories.forEach((item: any) => {
        newCategory = new InfoCategory();
        newCategory.fromJson(item);
        this.categories?.push(newCategory);
      });
    }
    if (
      enumeretionsObj?.orderStatus != null &&
      enumeretionsObj?.orderStatus.length > 0
    ) {
      this.orderStatus = [];
      let newOrderStatus: AbstractInfoEnum;
      enumeretionsObj?.orderStatus.forEach((item: any) => {
        newOrderStatus = new AbstractInfoEnum();
        newOrderStatus.fromJson(item);
        this.orderStatus?.push(newOrderStatus);
      });
    }
    if (
      enumeretionsObj?.productOrigins != null &&
      enumeretionsObj?.productOrigins.length > 0
    ) {
      this.productOrigins = [];
      let newProductOrigins: AbstractInfoEnum;
      enumeretionsObj?.productOrigins.forEach((item: any) => {
        newProductOrigins = new AbstractInfoEnum();
        newProductOrigins.fromJson(item);
        this.productOrigins?.push(newProductOrigins);
      });
    }
    if (
      enumeretionsObj?.marketplaces != null &&
      enumeretionsObj?.marketplaces.length > 0
    ) {
      this.marketplaces = [];
      let newMarketplace: AbstractInfoEnum;
      enumeretionsObj?.marketplaces.forEach((item: any) => {
        newMarketplace = new AbstractInfoEnum();
        newMarketplace.fromJson(item);
        this.marketplaces?.push(newMarketplace);
      });
    }

    if (
      enumeretionsObj?.groupingAttributes != null &&
      enumeretionsObj?.groupingAttributes.length > 0
    ) {
      this.groupingAttributes = [];
      let newGroupingAttributes: AbstractInfoEnum;
      enumeretionsObj?.groupingAttributes.forEach((item: any) => {
        newGroupingAttributes = new AbstractInfoEnum();
        newGroupingAttributes.fromJson(item);
        this.groupingAttributes?.push(newGroupingAttributes);
      });
    }

    if (enumeretionsObj?.coupon != null) {
      this.coupon = new InfoCoupon();
      this.coupon.fromJson(enumeretionsObj?.coupon);
    }
  }
}
