import Coupon from '../coupons/coupon';
import FreightSelected from '../freight/freight-selected';
import { CartItem } from './cart';

export default class PrepareCheckoutCart {
  public cartId?: number;
  public freightSelected?: FreightSelected;
  public coupon?: Coupon;
  public cartProducts?: CartItem[];
}
