import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  PasswordError,
  UserError,
} from '../../../pages/register-user/register-user.component';
import { PrimeNGModule } from '../../../primeng.module';
import { ToastService } from '../../../services/toast/toast.service';
import { UserService } from '../../../services/user/user.service';
import User from '../../models/user/user';
import { validCPF } from '../../utils/document';

@Component({
  selector: 'app-user-data',
  standalone: true,
  imports: [NgClass, NgIf, FormsModule, PrimeNGModule],
  templateUrl: './user-data.component.html',
  styleUrl: './user-data.component.scss',
  providers: [ToastService, MessageService],
})
export class UserDataComponent {
  @Input()
  public user: User = new User();

  @Input()
  public userError?: UserError;

  @Input()
  public readonly = false;

  public passwordError: PasswordError = new PasswordError();

  constructor(
    private userService: UserService,
    private confirmationService: ConfirmationService,
    public router: Router,
    private toastService: ToastService
  ) {}

  async onCompleteDocument() {
    const document = this.user?.document?.replace(/\D/g, '') || '';
    if (validCPF(document)) {
      try {
        await this.userService.validateDocument(document);
      } catch (e) {
        this.confirmationService.confirm({
          message: 'CPF já cadastrado. Deseja recuperar sua senha?',
          header: 'Confirmação',
          accept: async () => {
            this.router.navigate(['login']);
          },
        });
      }
    } else {
      this.toastService.error('CPF digitado é inválido!');
      this.user.document = '';
    }
  }

  onChangePassword() {
    if (this.user) {
      const validLowercase = new RegExp('^^(?=.*[a-z])').test(
        this.user.password || ''
      );
      const validUpperCase = new RegExp('^^(?=.*[A-Z])').test(
        this.user.password || ''
      );
      const validNumber = new RegExp('^^(?=.*[0-9])').test(
        this.user.password || ''
      );
      const validLength =
        (this.user?.password && this.user?.password.length >= 8) || false;
      this.passwordError.errorLowercase = validLowercase;
      this.passwordError.errorLength = validLength;
      this.passwordError.errorNumber = validNumber;
      this.passwordError.errorUpperCase = validUpperCase;
      this.passwordError.errorSame =
        (this.user.password &&
          this.user.password === this.user.confirmPassword) ||
        false;
    }
  }
}
