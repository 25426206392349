<p-inputMask
  name="zipCode"
  mask="99999-999"
  type="text"
  (keyup)="getFullAddress(false)"
  [(ngModel)]="zipCode"
  [placeholder]="placeholder"
  [disabled]="this.readOnly ?? false"
  [ngClass]="{
    'error-input-mask': errorInput
  }"
></p-inputMask>
<button
  (click)="getFullAddress(true)"
  title="Calcular frete e prazo"
  class="button-rm"
  aria-label="Calcular frete e prazo do produto"
>
  CALCULAR
</button>
<a
  *ngIf="showKnowZipCoded"
  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
  target="_blank"
  >Não sabe o CEP?</a
>
