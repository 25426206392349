<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<div class="login-customer">
  <div class="container">
    <div class="login-customer-main">
      <div class="login-customer-main-content">
        <div class="login-customer-main-content-signin">
          <h2 class="login-signin-title">IDENTIFIQUE-SE</h2>
          <div class="login-signin-inputs">
            <input
              type="text"
              placeholder="Informe seu e-mail"
              required
              pInputText
              name="email"
              [(ngModel)]="user.email"
              [ngClass]="{
                'error-input': userError.errorEmail
              }"
            />
            <p-password
              *ngIf="!firstStep"
              name="password"
              placeholder="Informe sua senha"
              [(ngModel)]="user.password"
              [toggleMask]="true"
              [feedback]="false"
              [ngClass]="{
                'error-input-prime': userError.errorPassword
              }"
            ></p-password>

            <button
              *ngIf="firstStep"
              class="button-rm button-rm-continue"
              aria-label="Verificar e-mail"
              type="submit"
              (click)="onClickContinue()"
            >
              CONTINUE
            </button>

            <p *ngIf="firstStep" (click)="onClickForgot()">
              Esqueceu sua senha?
            </p>

            <re-captcha
              *ngIf="!firstStep"
              (resolved)="resolvedcCaptcha($event)"
              siteKey="{{ siteKey }}"
            ></re-captcha>

            <div class="login-signin-inputs-forgot" *ngIf="!firstStep">
              <button
                class="button-rm"
                aria-label="Fazer login no sistema - ENTRAR"
                type="submit"
                (click)="onClickLogin()"
              >
                ENTRAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
