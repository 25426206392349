<app-breadcrumb
  *ngIf="showBreadcrumbCat"
  [category]="category"
  [showCategory]="true"
></app-breadcrumb>

<div class="categories-main" *ngIf="showBreadcrumbCat">
  <div class="container">
    <div class="categories-main-content">
      <a
        [routerLink]="item?.id?.toLowerCase()"
        class="categories-main-content-item"
        *ngFor="let item of subCategories"
      >
        {{ item.description }}
      </a>
    </div>
  </div>
</div>

<div class="categories-main">
  <app-products-shop
    *ngIf="showProductsOnOffer"
    [configCategories]="configCategories"
  ></app-products-shop>
</div>

<router-outlet (activate)="setConfig($event)"></router-outlet>
