import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage/session-storage.service';
import { UserService } from '../../../services/user/user.service';
import { StorageConstants } from '../../../shared/models/storage/storage-constants';

@Injectable({
  providedIn: 'root',
})
class LoginGuardService {
  constructor(
    private sessionStorageService: SessionStorageService,
    private userService: UserService
  ) {}

  async canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.sessionStorageService
        .get(StorageConstants.RM_ECOMMERCE_TOKEN)
        .then((res) => {
          if (res) {
            this.userService.goToCustomerOrders();
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((_err) => {
          this.userService.goToLogin();
          resolve(true);
        });
    });
  }
}

export const loginGuard: CanActivateFn = (route, state) => {
  return inject(LoginGuardService).canActivate();
};
