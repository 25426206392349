import { NgClass, NgIf } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FileSelectEvent } from 'primeng/fileupload';
import { PrimeNGModule } from '../../../primeng.module';
import { AddressService } from '../../../services/address/address.service';
import { ContactService } from '../../../services/contact/contact.service';
import { GoogleAnalyticsService } from '../../../services/google/google-analytics.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { ToastService } from '../../../services/toast/toast.service';
import TableOptions from '../../models/contact/table-options';
import Address from '../../models/user/address';
import { validCNPJ, validCPF } from '../../utils/document';
import extractNumbers from '../../utils/extract-numbers';
import { validEmail, validName, validPhone } from '../../utils/validators';

export class ContactDropdown {
  public name?: string;
  public value?: string;
}

export class ContactData {
  public subject?: TableOptions;
  public address?: Address;
  public email?: string;
  public message?: string;
  public fullName?: string;
  public phone?: string;
  public cpf?: string;
  public cnpj?: string;
  public attachments?: string[];
  public change: ContactDataChange = new ContactDataChange();
}

export class ContactDataChange {
  public dtReceipt?: string;
  public numberNFE?: number;
}

export class ContactError {
  public errorSubject = false;
  public errorZipCode = false;
  public errorCPF = false;
  public errorCNPJ = false;
  public errorEmail = false;
  public errorMessage = false;
  public errorFullName = false;
  public errorPhone = false;
  public errorNumberNFE = false;
}

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [FormsModule, NgClass, NgIf, PrimeNGModule],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss',
  providers: [ToastService, MessageService],
})
export class ContactFormComponent {
  @ViewChild('fileUpload')
  private fileUpload: any;

  @Input()
  public contactType?: string;

  public contactData: ContactData = new ContactData();
  public contactError: ContactError = new ContactError();
  public optionsSubject?: TableOptions[];
  public selectedOption: TableOptions = new TableOptions();
  public selectedStore: ContactDropdown = new ContactDropdown();
  public uploadedFiles: any[] = [];
  public zipCode?: string;
  public placeholder = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private addressService: AddressService,
    private contactService: ContactService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private metaServices: MetaTagsService,
    private toastService: ToastService,
    private loaderService: LoaderService
  ) {}

  async ngOnInit() {
    this.contactData = new ContactData();
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CONTACT,
        url[0]?.path,
        '',
        '',
        null
      );
    });

    this.placeholder =
      this.contactType === ContactService.TYPE_CONTACT
        ? 'Informações adicionais'
        : 'informe os itens que deseja devolver e   quantidade';

    this.optionsSubject = await this.contactService.getOptionsTablesOptions(
      this.contactType === ContactService.TYPE_SAC
        ? ContactService.TABLE_ID_SAC
        : ContactService.TABLE_ID_FORM
    );
  }

  onSelectFile(event: FileSelectEvent) {
    for (let file of event?.files) {
      if ((file as any)?.objectURL) {
        const reader = new FileReader();
        reader.onloadend = () => {
          (file as any).base64 = reader.result as string;
          this.uploadedFiles.push(file);
          if (this.uploadedFiles.length > 3) {
            this.uploadedFiles = [];
            this.fileUpload.clear();
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  async onClickSend() {
    if (this.uploadedFiles.length > 3) {
      this.toastService.error('É permitido enviar apenas 3 arquivos!');
      return;
    }
    this.contactError.errorFullName = !validName(
      this.contactData?.fullName || ''
    );
    this.contactError.errorEmail = !validEmail(this.contactData?.email || '');
    this.contactError.errorPhone = !validPhone(this.contactData?.phone || '');
    this.contactError.errorZipCode = !validName(this.zipCode || '');
    this.contactError.errorMessage = !validName(
      this.contactData?.message || ''
    );
    this.contactError.errorSubject = !this.selectedOption.id;

    let hasErrorSAC = false;
    if (this.isSAC()) {
      const orderId = this.contactData?.change?.numberNFE || 0;
      this.contactError.errorNumberNFE = orderId < 1000;

      this.contactData.change.dtReceipt = this.contactData?.change?.dtReceipt;

      this.contactError.errorCPF = !validCPF(this.contactData.cpf || '');

      hasErrorSAC =
        this.contactError.errorCPF || this.contactError.errorNumberNFE;
    }

    let hasErrorDealer = false;
    if (this.isDealer()) {
      if (!this.contactData.subject) {
        this.selectedOption = { name: ' Solicitação para Revenda' };
      }
      this.contactError.errorSubject = false;

      this.contactError.errorCNPJ = !validCNPJ(this.contactData.cnpj || '');
      hasErrorDealer = this.contactError.errorCNPJ;
    }

    let hasError =
      this.contactError.errorFullName ||
      this.contactError.errorEmail ||
      this.contactError.errorPhone ||
      this.contactError.errorZipCode ||
      this.contactError.errorMessage ||
      this.contactError.errorSubject ||
      hasErrorSAC ||
      hasErrorDealer;

    if (hasError) {
      this.toastService.error('É necessário preencher todos os campos!');
    } else {
      try {
        this.contactData.attachments = this.uploadedFiles.map(
          (file) => file.base64
        );
        this.contactData.subject = this.selectedOption;
        this.contactData.cpf = this.contactData?.cpf?.replace(/\D/g, '');

        if (!this.contactData.address || !this.contactData?.address?.zipCode) {
          await this.getAddress();
        }
        this.loaderService.show();
        this.googleAnalyticsService.sendEventContactForm(this.contactData);
        await this.contactService.send(this.contactData, this.contactType);
        this.toastService.success(
          'Mensagem enviada com sucesso! Em breve entraremos em contato.'
        );
        this.clean();
      } catch (error: any) {
        this.toastService.error(error);
      } finally {
        this.loaderService.hide();
      }
    }
  }

  async getAddress() {
    this.contactData.address = new Address();
    const valueNumber = extractNumbers(this.zipCode || '');
    if (valueNumber?.length === 8) {
      try {
        const address: Address = await this.addressService.getAddress(
          valueNumber
        );
        if (!address) {
          this.zipCode = '';
          this.toastService.error('CEP não localizado!3');
          return;
        }
        this.contactData.address = address;
      } catch (error) {
        this.zipCode = '';
        this.toastService.error('CEP não localizado!4');
      }
    }
  }

  isSAC() {
    return this.contactType === ContactService.TYPE_SAC;
  }

  isDealer() {
    return this.contactType === ContactService.TYPE_DEALER;
  }

  clean() {
    this.contactData = new ContactData();
    this.selectedOption = new TableOptions();
    this.selectedStore = new ContactDropdown();
    this.zipCode = '';
    this.uploadedFiles = [];
    this.fileUpload?.clear();
  }

  newChildren() {
    this.contactData.change = new ContactDataChange();
  }
}
