import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../../primeng.module';
import { AuthService } from '../../../services/auth/auth.service';
import { CartService, CartState } from '../../../services/cart/cart.service';
import { GoogleAnalyticsService } from '../../../services/google/google-analytics.service';
import { ProductsService } from '../../../services/product/products.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UserService } from '../../../services/user/user.service';
import { InfoCategory } from '../../models/app/info-category';
import { CartItem } from '../../models/cart/cart';
import User from '../../models/user/user';
import { CartHeaderComponent } from '../cart-header/cart-header.component';
import { CouponHomeComponent } from '../coupon-home/coupon-home.component';
import { MenuBarComponent } from '../menu-bar/menu-bar.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    FormsModule,
    CouponHomeComponent,
    CartHeaderComponent,
    MenuBarComponent,
    PrimeNGModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [ToastService, MessageService],
})
export class HeaderComponent implements OnInit {
  @Input()
  public set routeCategory(route: string) {
    this.productService.setSearchProductsSubCategory({ route: '', search: '' });
    this.searchInCategory = false;
    this.querySearch = '';
    this._route = route;
    this.isCategoryPage = InfoCategory.isCategoryPage(route);
    this.labelCategory = InfoCategory.formatCategoryName(route);
  }

  public _route = '';
  public countItensCart: number = 0;
  public itensCart: Array<CartItem> = [];
  public isCategoryPage = false;
  public labelCategory = '';
  public listener: any;
  public querySearch: string = '';
  public scrollTop: number = 0;
  public searchInCategory = false;
  public showCartHeader: boolean = false;
  public showCoupon = false;
  public showSearch: boolean = false;
  public user: User | undefined = new User();

  constructor(
    private authService: AuthService,
    private cartService: CartService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private productService: ProductsService,
    private router: Router,
    private renderer2: Renderer2,
    private toastService: ToastService,
    private userService: UserService,
    @Inject(DOCUMENT) private dom: any
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });

    this.cartService
      .getSearchProductsCart()
      .subscribe(async (res: CartState) => {
        if (res?.search) {
          this.getProductsCart(res?.showCart);
        }
      });

    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      const scrollPosition =
        e?.currentTarget?.pageYOffset ||
        e?.target?.documentElement?.scrollTop ||
        e?.target?.body?.scrollTop ||
        0;

      this.scrollTop = scrollPosition;
    });
  }

  async ngOnInit() {
    await this.productService.getValueMotorBando();
    this.getProductsCart(false);
  }

  async getProductsCart(showCartHeader: boolean) {
    this.itensCart = await this.cartService.getItems();
    this.countItensCart = this.itensCart?.length;
    if (showCartHeader) {
      this.showCartHeader = true;
    }
  }

  onClickSearch() {
    if (!this.querySearch) {
      this.toastService.error('É necessário informar um valor para busca!');
      return;
    }

    this.googleAnalyticsService.sendEventSearch(this.querySearch);
    if (this.searchInCategory) {
      this.productService.setSearchProductsSubCategory({
        route: this._route,
        search: this.querySearch,
      });
    } else {
      this.router.navigate(['produtos/' + this.querySearch]);
      this.querySearch = '';
    }
  }

  onClickShowSearch() {
    this.dom.body.scrollTop = 0;
    this.dom.documentElement.scrollTop = 0;
    this.showSearch = !this.showSearch;
  }

  onClickOpenCart() {
    if (this.countItensCart > 0) {
      this.userService.goToCart();
    }
  }
}
