<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-confirm-dialog></app-confirm-dialog>
<app-loader></app-loader>

<ng-container *ngIf="isCartOrCheckoutPage; else defaultHeader">
  <app-header-cart-checkout></app-header-cart-checkout>
</ng-container>

<ng-template #defaultHeader>
  <app-header [routeCategory]="routeCategory"></app-header>
</ng-template>

<div class="main-content">
  <router-outlet></router-outlet>
</div>

<app-footer [justRights]="isCartOrCheckoutPage"></app-footer>
