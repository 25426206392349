import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage: Storage | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage;
    }
  }

  async set(key: string, value: any) {
    if (this.storage) {
      const encryptedValue = btoa(
        unescape(encodeURIComponent(JSON.stringify(value)))
      );
      await this.storage.setItem(key, encryptedValue);
    }
  }

  async get(key: string) {
    if (this.storage) {
      const res = await this.storage.getItem(key);
      if (res) {
        const decrypted = decodeURIComponent(escape(atob(res)));
        return JSON.parse(decrypted);
      }
    }
    return null;
  }

  async removeItem(key: string) {
    if (this.storage) {
      await this.storage.removeItem(key);
    }
  }

  async clear() {
    if (this.storage) {
      await this.storage.clear();
    }
  }
}
