import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { PrimeNGModule } from '../../../primeng.module';
import Carrying from '../../models/freight/carrying';
import Freight from '../../models/freight/freight';
import FreightSelected from '../../models/freight/freight-selected';
import QuoteList from '../../models/freight/quote-list';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-freight-quotes-desktop',
  standalone: true,
  imports: [NgIf, PrimeNGModule, SharedModule],
  templateUrl: './freight-quotes-desktop.component.html',
  styleUrl: './freight-quotes-desktop.component.scss',
})
export class FreightQuotesDesktopComponent {
  @Input()
  public errorFreight: string | undefined;

  @Input()
  public freightSelected: FreightSelected | undefined;

  @Input()
  public loadingFreight = false;

  @Input()
  public set quotes(quotes: QuoteList | undefined) {
    this._quotes = quotes;
    this.setFreightSelected(this.getCarrying());
  }

  @Output()
  public onChangeFreightSelected: EventEmitter<Carrying> =
    new EventEmitter<Carrying>();

  public storePick = false;
  public _quotes: QuoteList | undefined;

  constructor() {}

  onChangeStorePick(event: CheckboxChangeEvent) {
    if (event?.checked?.length > 0) {
      this.setFreightSelected(this.getStorePick());
    } else {
      this.setFreightSelected(this.getCarrying());
    }
  }

  setFreightSelected(carrier: Carrying | undefined) {
    this.onChangeFreightSelected.emit(carrier);
  }

  getStorePick(): Carrying | undefined {
    const store = this._quotes?.carriers
      ?.filter((item: Carrying) => item?.optionFreight === Freight.STORE_PICKUP)
      .shift();

    return store;
  }

  getCarrying(): Carrying | undefined {
    const store = this._quotes?.carriers
      ?.filter((item: Carrying) => item?.optionFreight === Freight.CARRYING)
      .shift();

    return store;
  }
}
